.App {
}

.main-wrapper {
  padding: 10px 30px;
  width: 600px;
  max-width: 100%;
  margin: 100px auto 0;
}

.form-wrapper {
  text-align: center;
}

.input-field {
  margin: 10px 0;
}

.submit-button {
  width: 200px;
  max-width: 100%;
  margin-top: 20px;
  font-size: 16px !important;
}
.custom-spinner-class {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.content-wrapper {
  margin-top: 20px;
}

.content-item {
  display: flex;
  flex-direction: row;
}
.content-label {
  font-weight: bold;
}
.content-value {
  margin-left: 20px;
}